.App {
  text-align: center;
  background-color: #fff;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App__greeting {
  font-size: 30px;
  width: 100%;
  text-align: center;
}

.App__logo {
  width: 30%;
  object-fit: contain;
  max-width: 70px;
}

.App__button_container {
  background-color: #fff;
  border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  transition: background-color .1s;
}

.App__button_title {
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: #000;
  margin: 14px 48px;
  font-weight: 700;
  transition: color .1s;
}

.App__button_container:hover {
  background-color: #000;
  transition: background-color .1s;
}

.App__button_container:hover > .App__button_title {
  color: #fff;
  transition: color .1s;
}

a {
  text-decoration: none;
}